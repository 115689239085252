import React, { useState, useEffect } from "react";
import { Box, TextField, CircularProgress } from "@mui/material";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import { toast } from "react-toastify";
import API_ROUTES from "../../../config/apiConfig";
import { useLanguageState } from "../../../context/LanguageContext";
import loadTranslations from "../../../utils/loadTranslations";

const Card1 = () => {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  const [name, setName] = useState("");
  const [instructions, setInstructions] = useState("");
  const [assistantID, setAssistantID] = useState(null);
  const [loadingSend, setLoadingSend] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const userID = localStorage.getItem("group_id");

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(API_ROUTES.SETTING_AI_GET_DATA_API_1ST, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ group_id: userID }),
        });
        const result = await response.json();
        if (result.success) {
          setName(result.data.assistant_db.name);
          setInstructions(result.data.assistant_db.instructions);
          setAssistantID(result.data.assistant_id || "Not Found");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setAssistantID("Not Found");
      }
    };

    fetchData();
  }, [userID]);

  const handleCopyDefaultPrompt = () => {
    setInstructions(`Você é a assistente virtual do _______. Comporte-se como ________________.

    Você fala diversos idiomas e pode responder a pessoa no mesmo idioma que conversarem com você. 

    Responda no mesmo idioma que for perguntada, ou seja, traduza o conhecimento do arquivo de dados enviado para a mesmo idioma da pergunta que receber.  
    Se te perguntarem em portugues, responda em portugues, se te perguntarem em inglês, responda em inglês e assim por diante. 

    Use apenas o conhecimento e dados dos arquivos enviados, nunca use a base de conhecimentos gerais do chatgpt. Restrinja sua repostas as informações enviadas no arquivo.  

    Se pedirem para falar com a equipe, com uma atendente ou com um humano, responda: Por favor digite E para falar com nossa equipe. 

    Sempre limite suas respostas aos arquivos de informacões fornecidos. Não seja criativa. Nunca invente um email ou outra informação de contato.

    Quando nao souber uma resposta ou achar que uma pergunta feita não faz parte do contexto dos dados fornecidos, diga: Não tenho uma resposta para você sobre isso. Sugiro que digite E e fale com nossa equipe.

    Se falarem que você está repetindo o texto ou se perguntarem como que faz para parar a automação, diga para digitarem "sair" para desativar a automação.`);
  };

  const handleSend = async () => {
    const group_id = localStorage.getItem("group_id");

    if (!group_id) {
      toast.error("Group ID not found in localStorage");
      return;
    }

    setLoadingSend(true);

    try {
      const response = await fetch(API_ROUTES.SETTING_AI_SEND_DATA_API_1ST, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ group_id, instructions, name }),
      });
      const result = await response.json();
      if (result.success) {
        setName(result.assistant_db.name);
        setInstructions(result.assistant_db.instructions);
        toast.success("Data has been sent successfully!");
      } else {
        toast.error("Failed to send data.");
      }
    } catch (error) {
      toast.error("Error submitting data.");
    } finally {
      setLoadingSend(false);
    }
  };

  const handleSave = async () => {
    const group_id = localStorage.getItem("group_id");

    if (!group_id) {
      toast.error("Group ID not found in localStorage");
      return;
    }

    setLoadingSave(true);

    try {
      const response = await fetch(API_ROUTES.SETTING_AI_SAVE_DATA_1RD_CARD, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ group_id, instructions, name }),
      });

      const result = await response.json();
      if (result.success) {
        setName(result.assistant_db.name);
        setInstructions(result.assistant_db.instructions);
        toast.success(translations.setting_AI?.saved_successfully);
      } else {
        toast.error("Failed to save data.");
      }
    } catch (error) {
      toast.error("Error saving data.");
    } finally {
      setLoadingSave(false);
    }
  };

  return (
    <>
      <MDTypography variant="p" sx={{ mb: 1 }} style={{ fontSize: "14px" }}>
        Assistant ID: {assistantID === null ? "Loading..." : assistantID}
      </MDTypography>
      <TextField
        fullWidth
        label={translations.settings?.assistant_name}
        variant="outlined"
        margin="normal"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        fullWidth
        label={translations.settings?.assistant_instructions}
        multiline
        rows={8}
        variant="outlined"
        margin="normal"
        value={instructions}
        onChange={(e) => setInstructions(e.target.value)}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 2,
          flexDirection: { xs: "column", md: "row" }, // Responsive direction
          gap: 2, // Add space between buttons when in column layout
        }}
      >
        <MDButton
          type="button"
          variant="gradient"
          color="black1"
          style={{ color: "black" }}
          onClick={handleCopyDefaultPrompt}
          disabled={loadingSend || loadingSave}
        >
          {translations.settings?.copy_default_prompt}
        </MDButton>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" }, // Responsive direction
            gap: 2, // Add space between buttons when in column layout
          }}
        >
          <h1></h1>
          <MDButton
            type="button"
            variant="gradient"
            color="info"
            onClick={handleSend}
            disabled={loadingSend}
          >
            {loadingSend ? (
              <CircularProgress size={24} color="info" />
            ) : (
              translations.buttons_and_common_words?.send
            )}
          </MDButton>
          <MDButton
            type="button"
            variant="gradient"
            color="dark"
            onClick={handleSave}
            disabled={loadingSave}
          >
            {loadingSave ? (
              <CircularProgress size={24} color="info" />
            ) : (
              translations.buttons_and_common_words?.save
            )}
          </MDButton>
        </Box>
      </Box>
    </>
  );
};

export default Card1;
